////
/// Pagination
////

.pagination--prevnext {
  clear: both;
  @media #{$medium-up} {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 2vmin;
  }
  margin: 3rem auto;
}

.pagination--prev {
  padding-left: 1em;
  a:before {
    left: -1em;
    content:"< ";
  }
}

.pagination--next {
  padding-right: 1em;
  text-align: right;
  a:before {
    right: -1em;
    content:" >";
  }
}

.pagination--item {
  position: relative;
  display: block;
  padding: 1em 0;
  font-size: $smaller;
  color: #333;
  &:before {
    position: absolute;
    
  }
}

.pagination--item:active {
  background: $color-grey-light;
}
