////
/// Very basic styles
////

html,
body {
  box-sizing: border-box;
  margin: 0;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

// Images
// -------------------------

img {
  max-width: 100%;
  height: auto;
  vertical-align: middle;
  border: 0;
  -ms-interpolation-mode: bicubic;
}

// We need to revert these for Google maps cause else it breaks!
%img--no-max,
.img--no-max {
  max-width: none;
}


// Figures
// -------------------------

figure > img {
  display: block;
}

figcaption {
  font-size: $smaller;
}

// Links
// -------------------------

a {
  text-decoration: underline;
  color: $link-color;
  &:visited {
    color: $link-color;
  }
  &:hover {
    text-decoration: none;
  }
  &:focus {
    outline: thin dotted;
  }
  &:hover, &:active {
    outline: none;
  }
}

// Lists
// -------------------------

ul,ol {
  margin-bottom: $base-line;
  li {
    margin-bottom: 0.5rem;
  }
}

// List options
// -------------------------

// Unstyled keeps list items block level, just removes default browser padding and list-style
.list-unstyled {
  padding-left: 0;
  list-style: none;
}

// Inline turns list items into inline-block
.list-inline {
  @extend .list-unstyled;

  > li {
    display: inline-block;
    margin: 0 -5px 0 0;
  }
}

// Description Lists
// -------------------------

dl {
  margin-top: 0; // Remove browser default
  margin-bottom: $base-line;
}
dt,
dd {
  line-height: $line-height-base;
}
dt {
  font-weight: bold;
}
dd {
  margin-left: 0; // Undo browser default
}

// Blockquotes
// -------------------------

blockquote,
blockquote p,
q {
  color: $color-grey;
}

blockquote {
  padding-left: $base-line / 2;
  font-size: $base-em * $ms1;
  letter-spacing: -1px;
  border-left: 4px solid $color-grey;
  > :last-child {
    margin-bottom: 0;
  }
}

blockquote cite {
  display: block;
  color: $color-grey-dark;
}

blockquote cite:before {
  content: "\2014 \0020";
}

blockquote cite a,
blockquote cite a:visited {
  color: $color-grey-dark;
}


// Code formatting
// -------------------------

pre,
code {
  font-size: 15px;
  border: 1px solid $color-grey-light;
  border-radius: 3px;
  background-color: #eef;
}

code {
  padding: 1px 5px;
}

pre {
  overflow-x: scroll;
  padding: 8px 12px;
  > code {
    padding-right: 0;
    padding-left: 0;
    border: 0;
  }
}

// table {
//   width: 100%;
//   table-layout: fixed;
//   border: 1px solid #ccc;
//   > thead, > tbody, > tfoot {
//     > tr {
//       > th, > td {
//         border: 1px solid #ccc;
//       }
//     }
//   }
//   > thead > tr {
//     > th, > td {
//       border-bottom-width: 2px;
//     }
//   }
// }
