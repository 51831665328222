////
/// Post
////

.post {
  padding-bottom: $base-line;
  figure {
    text-align: center;
  }
  img {
    margin: 0.5em auto 1.5em;
  }
}

.posts-archive {
  @include list-inline();
  > li {
    &:not(:last-child):after {
      content: ' - ';
    }
 }
}
