////
/// Navigation
////

// Navigation main
// inspired https://medium.com/@kollinz/hamburger-menu-alternatives-for-mobile-navigation-a3a3beb555b8#.hg6g5yhtd

nav {
  ul {
    list-style: none;
  }
  // a {
  //   text-decoration: none;
  // }
}

.nav-main {
  @media #{$small-only} {
    overflow-y: hidden !important;
    height: 40px !important;
    border-top: 1px solid;
    border-bottom: 1px solid;
  }
  ul {
    margin: 0;
    padding: 0.3em 0;
    min-width: 100%;
    list-style: none;
    text-align: center;
    @media #{$small-only} {
      display: block;
      white-space: nowrap;
      padding-bottom: 100px !important;
      overflow-y: hidden;
      overflow-x: auto;
    }
    @media #{$medium-up} {
      .wrapper--flex & {
        text-align: right;
      }
    }
  }
  li {
    display: inline-block;
    text-align: center;
    margin: 0;
    // padding: 0.2rem 1.6rem 0;
    padding: 0rem 0.5rem 0;
  }
  a {
    font-weight: bold;
    & + & {
       margin-left: 1em;
    }
  }
}