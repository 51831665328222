@charset "utf-8";

// Import partials from `sass_dir` (defaults to `_sass`)
//https://fonts.googleapis.com/css?family=Google+Sans:100,300,400,500,700,900,100i,300i,400i,500i,700i,900i
// https://fonts.googleapis.com/css?family=Google+Sans

@import url(https://fonts.googleapis.com/css?family=Google+Sans:300,400,500,700);
$base-font-name: 'Google Sans';
$title-font-name: 'Google Sans';

// Configuration
@import "shreyasmm/shreyasmm";

