////
/// Media queries
////

// Media Query breakpoint
// https://medium.freecodecamp.com/the-100-correct-way-to-do-css-breakpoints-88d6a5ba1862#.59e713wwa
//-------------------------------------

$screen:                    "only screen";
$tiny:                      20;     /* 320px  */
$small:                     37.5;   /* 600px  */
$medium:                    56.25;  /* 900px  */
$large:                     75;     /* 1200px */
$xlarge:                    112.5;  /* 1800px */
$xxlarge:                   150;    /* 2400px */


// Media Query Ranges
//-------------------------------------

$tiny-range                 : (0em, $tiny * 1em) !default;
$small-range                : (0em, $small * 1em - 0.063em) !default;
$medium-range               : ($medium * 1em + 0.063em, $large * 1em) !default;
$large-range                : ($large * 1em + 0.063em, $xlarge * 1em) !default;
$xlarge-range               : ($xlarge * 1em + 0.063em, $xxlarge * 1em) !default;
$xxlarge-range              : ($xxlarge * 1em + 0.063em, 99999999em) !default;


// Media Query Ranges :: Mixin (from Foundation CSS)
//-------------------------------------

// functions

@function lower-bound($range){
  @if length($range) <= 0 {
    @return 0;
  }
  @return nth($range,1);
}

@function upper-bound($range) {
  @if length($range) < 2 {
    @return 999999999999;
  }
  @return nth($range, 2);
}

// Call functions and declare media range variable

$landscape    : "#{$screen} and (orientation: landscape)";
$portrait     : "#{$screen} and (orientation: portrait)";
$tiny-only    : "#{$screen} and (max-width:#{upper-bound($tiny-range)})";
$small-up     : "#{$screen} and (min-width:#{lower-bound($small-range)})";
$small-only   : "#{$screen} and (max-width:#{upper-bound($small-range)})";
$medium-up    : "#{$screen} and (min-width:#{lower-bound($medium-range)})";
$medium-only  : "#{$screen} and (min-width:#{lower-bound($medium-range)}) and (max-width:#{upper-bound($medium-range)})";
$large-up     : "#{$screen} and (min-width:#{lower-bound($large-range)})";
$large-only   : "#{$screen} and (min-width:#{lower-bound($large-range)}) and (max-width:#{upper-bound($large-range)})";
$xlarge-up    : "#{$screen} and (min-width:#{lower-bound($xlarge-range)})";
$xlarge-only  : "#{$screen} and (min-width:#{lower-bound($xlarge-range)}) and (max-width:#{upper-bound($xlarge-range)})";
$xxlarge-up   : "#{$screen} and (min-width:#{lower-bound($xxlarge-range)})";
$xxlarge-only : "#{$screen} and (min-width:#{lower-bound($xxlarge-range)}) and (max-width:#{upper-bound($xxlarge-range)})";


// Unison
// speaking with JS with unison.js
// http://bjork24.github.io/Unison/
//-------------------------------------

// config
$debug: false;

// Unison media
$mq-sync:
usn-small           0,
usn-medium          $medium * 1em
;
// usn-large          $large,
// usn-x-large        $xlarge,
// usn-xx-large       $xxlarge

// build each media query for js ingestion
@each $mq in $mq-sync {
  @media screen and (min-width: nth($mq, 2)) {
    head { font-family: "#{nth($mq, 1)} #{nth($mq, 2)}"; }
    body:after { content: "#{nth($mq, 1)} - min-width: #{nth($mq, 2)}"; }
  }
}
head {
// set clear on head to show Unison is set up correctly
clear: both;
// store hash of all breakpoints
title { font-family: "#{$mq-sync}"; }
}
// debug styles to see breakpoint info
body:after {
  display: none;
}
// hide elements for conditional loading
// only used for responsive comments plugin
*[data-usn-if] { display: none; }


